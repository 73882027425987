<template>
  <div class="hold-transition">
    <div
      class="modal fade"
      id="modal-form-servicios"
      role="dialog"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">
              Servicios Solicitud - {{ data_solicitud.id }}
            </h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModal1"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row justify-content-center">
              <div class="col-md-4">
                <div class="card">
                  <div class="card-body text-muted text-xs">
                    <ul class="ml-4 mb-0 fa-ul">
                      <li>
                        <span class="fa-li">
                          <i class="fas fa-lg fa-chalkboard-teacher"></i>
                        </span>
                        <strong>Solicitud</strong>
                        <div class="row">
                          <div class="col-md-5">Tipo:</div>
                          <div class="col-md-7 mb-1">
                            <strong>{{ data_solicitud.tipoSolicitud }}</strong>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-5">Clase:</div>
                          <div class="col-md-7 mb-1">
                            <strong>{{
                              data_solicitud.clase_solicitud
                            }}</strong>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-5">Fecha:</div>
                          <div class="col-md-7 mb-1">
                            <strong>{{ data_solicitud.fecha }}</strong>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-5">Bloque:</div>
                          <div class="col-md-7 mb-1">
                            <strong>{{
                              data_solicitud.bloque
                                ? data_solicitud.bloque.nombre
                                : ""
                            }}</strong>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-5">Sitio:</div>
                          <div class="col-md-7 mb-1">
                            <strong>{{
                              data_solicitud.sitio
                                ? data_solicitud.sitio.nombre
                                : ""
                            }}</strong>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-5">Usuario Solicitante:</div>
                          <div class="col-md-7 mb-1">
                            <strong>{{
                              data_solicitud.user
                                ? data_solicitud.user.name
                                : ""
                            }}</strong>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-5">Correo Usuario:</div>
                          <div class="col-md-7 mb-1">
                            <strong>{{
                              data_solicitud.user
                                ? data_solicitud.user.email
                                : ""
                            }}</strong>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-5">Celular Usuario:</div>
                          <div class="col-md-7 mb-1">
                            <strong>{{
                              data_solicitud.user ? data_solicitud.user.cel : ""
                            }}</strong>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-5">Presupuesto Verificado:</div>
                          <div class="col-md-7 mb-1">
                            <i
                              class="fa"
                              :class="
                                data_solicitud.presupuesto_verificado
                                  ? 'fa-check text-success'
                                  : 'fa-times-circle text-danger'
                              "
                            ></i>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-5">Estado:</div>
                          <div class="col-md-7 mb-1">
                            <span
                              class="badge"
                              :class="
                                data_solicitud.estado == 1
                                  ? 'bg-warning'
                                  : data_solicitud.estado == 2
                                  ? 'bg-success'
                                  : data_solicitud.estado == 3
                                  ? 'bg-danger'
                                  : data_solicitud.estado == 4
                                  ? 'bg-lime'
                                  : 'bg-secondary'
                              "
                            >
                              {{ data_solicitud.nEstado }}
                            </span>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-5">Justificación:</div>
                          <div class="col-md-7 mb-1">
                            <small>{{ data_solicitud.justificacion }}</small>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-5">Observaciones:</div>
                          <div class="col-md-7 mb-3">
                            <small>{{ data_solicitud.observaciones }}</small>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div
                class="col-md-4"
                v-if="
                  data_solicitud.solicitudes_rutas &&
                    data_solicitud.solicitudes_rutas.length > 0
                "
              >
                <div class="card">
                  <div class="card-body text-muted text-xs">
                    <ul class="ml-4 mb-0 fa-ul">
                      <li>
                        <span class="fa-li">
                          <i class="fas fa-road"></i>
                        </span>
                        <strong>Ruta Origen</strong>
                        <div class="row">
                          <div class="col-md-5">Tipo Sitio:</div>
                          <div class="col-md-7 mb-1">
                            <strong class="badge bg-success">{{
                              data_solicitud.solicitudes_rutas[0].nTipoSitio
                            }}</strong>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-5">Sitio:</div>
                          <div class="col-md-7 mb-1">
                            <strong>{{
                              data_solicitud.solicitudes_rutas[0].nombre
                            }}</strong>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-5">Dirección:</div>
                          <div class="col-md-7 mb-1">
                            <strong>{{
                              data_solicitud.solicitudes_rutas[0].direccion
                            }}</strong>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-5">Nombre Contacto:</div>
                          <div class="col-md-7 mb-1">
                            <strong>{{
                              data_solicitud.solicitudes_rutas[0]
                                .nombre_contacto
                            }}</strong>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-5">Número:</div>
                          <div class="col-md-7 mb-1">
                            <strong>{{
                              data_solicitud.solicitudes_rutas[0]
                                .telefono_contacto
                            }}</strong>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-5">Correo:</div>
                          <div class="col-md-7 mb-1">
                            <strong>{{
                              data_solicitud.solicitudes_rutas[0].email_contacto
                            }}</strong>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div
                class="col-md-4"
                v-if="
                  data_solicitud.solicitudes_rutas &&
                    data_solicitud.solicitudes_rutas.length > 1
                "
              >
                <div class="card">
                  <div class="card-body text-muted text-xs">
                    <ul class="ml-4 mb-0 fa-ul">
                      <li>
                        <span class="fa-li">
                          <i class="fas fa-road"></i>
                        </span>
                        <strong>Ruta Destino</strong>
                        <div class="row">
                          <div class="col-md-5">Tipo Sitio:</div>
                          <div class="col-md-7 mb-1">
                            <strong class="badge bg-primary">{{
                              data_solicitud.solicitudes_rutas[1].nTipoSitio
                            }}</strong>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-5">Sitio:</div>
                          <div class="col-md-7 mb-1">
                            <strong>{{
                              data_solicitud.solicitudes_rutas[1].nombre
                            }}</strong>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-5">Dirección:</div>
                          <div class="col-md-7 mb-1">
                            <strong>{{
                              data_solicitud.solicitudes_rutas[1].direccion
                            }}</strong>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-5">Nombre Contacto:</div>
                          <div class="col-md-7 mb-1">
                            <strong>{{
                              data_solicitud.solicitudes_rutas[1]
                                .nombre_contacto
                            }}</strong>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-5">Número:</div>
                          <div class="col-md-7 mb-1">
                            <strong>{{
                              data_solicitud.solicitudes_rutas[1]
                                .telefono_contacto
                            }}</strong>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-5">Correo:</div>
                          <div class="col-md-7 mb-1">
                            <strong>{{
                              data_solicitud.solicitudes_rutas[1].email_contacto
                            }}</strong>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-body pb-0">
            <div class="row justify-content-center">
              <div class="form-group col-md-2">
                <label>N° Servicio</label>
                <input
                  type="number"
                  v-model="filtros.servicio_id"
                  placeholder="N°Servicio"
                  label="id"
                  class="form-control form-control-sm"
                  @input="getIndex()"
                />
              </div>
              <div class="form-group col-md-2">
                <label>Tipo Servicio</label>
                <select
                  id="tipo_servicio"
                  class="form-control form-control-sm"
                  v-model="filtros.tipo_servicio"
                  @change="getIndex()"
                >
                  <option value="">Seleccione...</option>
                  <option
                    v-for="tipo_servicio in listasForms.tipos_servicios"
                    :key="tipo_servicio.numeracion"
                    :value="tipo_servicio.numeracion"
                  >
                    {{ tipo_servicio.descripcion }}
                  </option>
                </select>
              </div>
              <div class="form-group col-md-2">
                <label>Placa</label>
                <input
                  type="text"
                  class="form-control form-control-sm"
                  v-model="filtros.placa"
                  @input="getIndex()"
                />
              </div>
              <div class="form-group col-md-2">
                <label>Conductor/Operador</label>
                <select
                  v-model="filtros.persona_type"
                  class="form-control form-control-sm p-0"
                  @change="getIndex()"
                >
                  <option value="">Seleccione...</option>
                  <option value="App\Conductor">Conductor</option>
                  <option value="App\Operario">Operario</option>
                </select>
              </div>
              <div class="form-group col-md-4">
                <label>Nombre Conductor</label>
                <input
                  type="text"
                  id="nombre"
                  class="form-control form-control-sm"
                  v-model="filtros.nombre"
                  @input="getIndex()"
                />
              </div>
              <div class="form-group col-md-4">
                <label>Empresa</label>
                <v-select
                  v-model="empresa"
                  label="razon_social"
                  class="form-control form-control-sm p-0"
                  :class="$store.getters.getDarkMode ? 'dark-vselect' : ''"
                  :options="listasForms.empresas"
                  :filterable="false"
                  @search="buscarEmpresas"
                  @input="getIndex()"
                ></v-select>
              </div>
              <div class="form-group col-md-4">
                <label>Bloque</label>
                <v-select
                  :class="[$store.getters.getDarkMode ? 'dark-vselect' : '']"
                  v-model="bloque"
                  placeholder="Nombre"
                  label="nombre"
                  :options="listasForms.bloques"
                  @input="getIndex()"
                  class="form-control form-control-sm p-0"
                ></v-select>
              </div>
              <div class="form-group col-md-4">
                <label>Sitio</label>
                <v-select
                  :class="[$store.getters.getDarkMode ? 'dark-vselect' : '']"
                  v-model="sitio"
                  placeholder="Nombre"
                  label="nombre"
                  :options="listasForms.sitios"
                  @input="getIndex()"
                  class="form-control form-control-sm p-0"
                ></v-select>
              </div>
              <div class="form-group col-md-2">
                <label>Fecha Inicial</label>
                <input
                  type="date"
                  id="fecha_ini"
                  class="form-control form-control-sm"
                  v-model="filtros.fecha_ini"
                  @input="getIndex()"
                />
              </div>
              <div class="form-group col-md-2">
                <label>Fecha Final</label>
                <input
                  type="date"
                  id="fecha_fin"
                  class="form-control form-control-sm"
                  v-model="filtros.fecha_fin"
                  @input="getIndex()"
                />
              </div>
              <div class="col-md-2">
                <label>Estado</label>
                <select
                  id="estado"
                  class="form-control form-control-sm"
                  v-model="filtros.estado"
                  @change="getIndex()"
                >
                  <option value="">Seleccione...</option>
                  <option
                    v-for="estado in listasForms.estados"
                    :key="estado.numeracion"
                    :value="estado.numeracion"
                  >
                    {{ estado.descripcion }}
                  </option>
                </select>
              </div>
              <div class="col-md-2 text-center pt-2">
                <div class="btn-group">
                  <button
                    type="button"
                    class="btn bg-navy mt-3"
                    @click="getIndex()"
                  >
                    <i class="fas fa-search"></i>
                  </button>
                  <button
                    type="button"
                    class="btn btn-danger mt-3"
                    @click="limpiar()"
                  >
                    <i class="fas fa-broom"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-body p-0">
            <table
              class="table table-responsive table-sm text-nowrap team-roster-table mb-0"
              style="font-size: 0.85em"
            >
              <thead class="thead bg-light">
                <tr class="text-center">
                  <th class="col-1"># Servicio</th>
                  <th class="col-1"># Solicitud</th>
                  <th>Tipo Servicio</th>
                  <th>Tipo Vh / Equi</th>
                  <th class="col-1">Placa</th>
                  <th class="col-3">Conductor/Operador</th>
                  <th class="col-5">Empresa</th>
                  <th>Fecha Inicial</th>
                  <th>Fecha Final</th>
                  <th class="col-4">Bloque</th>
                  <th>Sitio</th>
                  <th>Estado</th>
                </tr>
              </thead>
              <tbody v-for="serv in servicios.data" :key="serv.id">
                <tr data-widget="expandable-table" aria-expanded="false">
                  <td class="text-center col-1">{{ serv.id }}</td>
                  <td class="text-center col-1">
                    {{
                      serv.postulacion.det_solicitud_interna.solicitud_interna
                        .id
                    }}
                  </td>
                  <td class="text-center col-1">
                    <span
                      class="badge"
                      :class="serv.tipo_servicio == 1 ? 'bg-navy' : 'bg-info'"
                      >{{ serv.nTipoServicio }}</span
                    >
                  </td>

                  <td class="text-center">
                    <span v-if="serv.postulacion.det_solicitud_interna.tipo_servicio == 1">
                      {{
                        serv.postulacion.det_solicitud_interna.tipo_vh_equi == 1
                          ? serv.postulacion.det_solicitud_interna.nTipoVh +
                            " - " +
                            serv.postulacion.det_solicitud_interna.nTipoRemolque
                          : serv.postulacion.det_solicitud_interna.nTipoVh
                      }}
                    </span>
                    <span v-if="serv.postulacion.det_solicitud_interna.tipo_servicio == 2">
                      {{ serv.postulacion.det_solicitud_interna.nTipoEqui }}
                    </span>
                  </td>
                  <td class="text-center text-wrap">
                    <div>{{ serv.equipo.placa }}</div>
                    <div>
                      <small>{{
                        serv.tipo_servicio == 2
                          ? `Serial - ${serv.equipo.serial}`
                          : ""
                      }}</small>
                    </div>
                  </td>
                  <td class="text-wrap">
                    <div
                      v-if="serv.persona !== null && serv.persona !== undefined"
                    >
                      <div>
                        {{
                          serv.persona_type == "App\\Conductor"
                            ? "Conductor: "
                            : "Operario: "
                        }}
                        <span class="badge badge-info float-right">
                          {{ serv.persona.numero_documento }}
                        </span>
                      </div>
                      {{ serv.persona.nombres }}
                      {{ serv.persona.apellidos }}
                    </div>
                  </td>
                  <td class="text-wrap">
                    {{ serv.empresa ? serv.empresa.razon_social : "" }}
                  </td>
                  <td class="text-center text-wrap">{{ serv.fecha_ini }}</td>
                  <td class="text-center text-wrap">{{ serv.fecha_fin }}</td>
                  <td class="text-wrap">
                    {{
                      serv.bloque
                        ? serv.bloque.nombre
                        : postulacion &&
                          postulacion.det_solicitud_interna &&
                          postulacion.det_solicitud_interna.solicitud_interna &&
                          postulacion.det_solicitud_interna.solicitud_interna
                            .bloque
                        ? postulacion.det_solicitud_interna.solicitud_interna
                            .bloque.nombre
                        : ""
                    }}
                  </td>
                  <td class="text-wrap">
                    {{
                      serv.sitio
                        ? serv.sitio.nombre
                        : postulacion &&
                          postulacion.det_solicitud_interna &&
                          postulacion.det_solicitud_interna.solicitud_interna &&
                          postulacion.det_solicitud_interna.solicitud_interna
                            .sitio
                        ? postulacion.det_solicitud_interna.solicitud_interna
                            .sitio.nombre
                        : ""
                    }}
                  </td>
                  <td class="text-center">
                    <span
                      class="badge"
                      :class="
                        serv.estado == 1
                          ? 'bg-navy'
                          : serv.estado == 2
                          ? 'badge-warning'
                          : serv.estado == 3
                          ? 'badge-success'
                          : serv.estado == 4
                          ? 'bg-olive'
                          : serv.estado == 5
                          ? 'badge-info'
                          : serv.estado == 6
                          ? 'badge-secondary'
                          : 'bg-light'
                      "
                    >
                      {{ serv.nEstado }}
                    </span>
                    <div class="text-left"></div>
                    <div>
                      <span
                        class="badge bg-light"
                        v-if="serv.firmas.length > 0"
                      >
                        <small class="text-secundary">Firmado Por:</small>
                        <div>
                          <span v-for="firma in serv.firmas" :key="firma.id">
                            <p class="m-0">{{ firma.nTipoFirma }}</p>
                          </span>
                        </div>
                      </span>
                    </div>
                  </td>
                </tr>
                <tr class="expandable-body d-none">
                  <td class="text-wrap" colspan="13">
                    <CsSolicitudesInternasFirmas
                      style="display: none"
                      :servicio="serv"
                      ref="CsSolicitudesInternasFirmas"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="card-footer">
            <div class="float-left" v-if="servicios.total">
              Mostrando del <b>{{ servicios.from }}</b> al
              <b>{{ servicios.to }}</b> de un total:
              <b>{{ servicios.total }}</b> Registros
            </div>
            <div class="float-left" v-else>
              <span class="badge badge-danger">
                No hay registros para mostrar
              </span>
            </div>
            <pagination
              :data="servicios"
              @pagination-change-page="getIndex"
              :limit="5"
              class="float-right m-0"
            ></pagination>
          </div>
          <div class="modal-footer justify-content-between">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Cerrar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import pagination from "laravel-vue-pagination";
import vSelect from "vue-select";
import CsSolicitudesInternasFirmas from "./CsSolicitudesInternasFirmas";

export default {
  name: "CsSolicitudesInternasServicios",

  components: {
    pagination,
    vSelect,
    CsSolicitudesInternasFirmas,
  },

  data() {
    return {
      data_solicitud: [],
      servicios: {},
      filtros: {},
      listasForms: {
        empresas: [],
      },
      empresa: [],
      bloque: [],
      sitio: [],
      page: 1,
    };
  },

  methods: {
    async getServicios(solicitud) {
      this.data_solicitud = solicitud;
      this.filtros = {};
      this.empresa = [];
      this.bloque = [];
      this.sitio = [];
      await this.getIndex();
    },

    async getIndex(page = 1) {
      this.$parent.cargando = true;
      this.servicios = {};
      const me = this;
      this.filtros.solicitud_id = this.data_solicitud.id;
      this.filtros.empresa_id = this.empresa ? this.empresa.id : null;
      this.filtros.bloque_id = this.bloque ? this.bloque.id : null;
      this.filtros.sitio_id = this.sitio ? this.sitio.id : null;
      this.page = page;
      await axios
        .get(
          "/api/funcionariosFrontera/solicitudesInternasServicios?page=" + page,
          {
            params: this.filtros,
          }
        )
        .then((response) => {
          this.$parent.cargando = false;
          this.servicios = response.data;
        })
        .catch(function(error) {
          me.$parent.cargando = false;
          me.$swal({
            icon: "error",
            title: "Ocurrió un error: " + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    async limpiar() {
      this.filtros = {};
      this.empresa = [];
      this.bloque = [];
      this.sitio = [];
      await this.getIndex();
    },

    getTipoServicio() {
      axios.get("/api/lista/128").then((response) => {
        this.listasForms.tipos_servicios = response.data;
      });
    },

    getBloques() {
      axios.get("/api/admin/bloques/lista").then((response) => {
        this.listasForms.bloques = response.data;
      });
    },

    getSitios() {
      axios.get("/api/admin/sitios/lista").then((response) => {
        this.listasForms.sitios = response.data;
      });
    },

    getEstados() {
      axios.get("/api/lista/143").then((response) => {
        this.listasForms.estados = response.data;
      });
    },

    getFirmantes() {
      axios.get("/api/lista/171").then((response) => {
        this.firmantes = response.data;
      });
    },

    buscarEmpresas(search) {
      if (search != "") {
        this.$parent.cargando = true;
        let me = this;
        var url = "api/admin/empresas/lista";
        axios
          .get(url, {
            params: {
              razon: search,
              linea_negocio_id: [4],
            },
          })
          .then(function(response) {
            me.listasForms.empresas = response.data;
            me.$parent.cargando = false;
          })
          .catch(function(error) {
            me.$parent.cargando = false;
            me.$swal({
              icon: "error",
              title: "Ocurrió un error: " + error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },
  },

  beforeMount() {
    this.getTipoServicio();
    this.getBloques();
    this.getSitios();
    this.getEstados();
    this.getFirmantes();
  },
};
</script>
